import { DialogController }                from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseFormViewModel }               from 'base-form-view-model';
import { BulletinRevisionFormSchema }      from 'modules/management/specifications/bulletins/bulletin-revisions/form-schema';
import { BulletinRevisionFilesRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/files-repository';
import { BulletinRevisionsRepository }     from 'modules/management/specifications/bulletins/bulletin-revisions/services/repository';
import { AppContainer }                    from 'resources/services/app-container';
import { Downloader }                      from 'resources/services/downloader';

@inject(AppContainer, DialogController, BulletinRevisionsRepository, BulletinRevisionFilesRepository, BulletinRevisionFormSchema, Downloader)
export class ViewBulletinRevisionModal extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';
    formId      = 'management.specifications.bulletins.revisions.view-form';
    readonly    = true;

    @bindable alert = {};
    model           = {};
    schema          = {};

    @bindable settings = {
        title:   'form.title.view-record',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param bulletinRevisionFilesRepository
     * @param formSchema
     * @param downloader
     */
    constructor(appContainer, dialogController, repository, bulletinRevisionFilesRepository, formSchema, downloader) {
        super(appContainer);

        this.dialogController                = dialogController;
        this.repository                      = repository;
        this.bulletinRevisionFilesRepository = bulletinRevisionFilesRepository;
        this.formSchema                      = formSchema;
        this.downloader                      = downloader;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/bulletins/bulletin-revisions/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.manage',
            'management.specifications.bulletins.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);
        this.schema       = this.formSchema.schema(this, true);
        this.globalSchema = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }

    /**
     * Downloads the file
     *
     * @param file
     */
    downloadFile(file) {
        this.bulletinRevisionFilesRepository.download(file.id)
            .then(result => {
                if (result) {
                    // remove the file extension from the file name
                    const fileName = file.display_name.replace(/\.[^/.]+$/, '');
                    this.downloader.download(result, fileName, file.extension);
                }
            });
    }
}
